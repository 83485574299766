import React from 'react'
import { graphql } from 'gatsby'
import Pagina from '../components/Pagina'
import BannerLogin from '../components/BannerLogin'

const LoginUsuario = ({ data }) => {
  return <>
    <Pagina pagina={data.pagina} />
    <BannerLogin
      desktop={data.paralax.childImageSharp.gatsbyImageData}
      mobile={data.paralaxMobile.childImageSharp.gatsbyImageData}
      className="coletaOleo"
      paralax="paralaxImage"
      titulo={<>Solicite sua coleta<br/> na área exlusiva<br/>ou fale diretamente<br/> com a gente pelo</>}
      disk={<>Disque Coleta Lwart <br/>0800 701 0088</>}
      cor="blue"
      path="/area-cliente/solicite-sua-coleta/coleta-de-oleo/"
      originPath="/login/"
    />
  </>
}
export default LoginUsuario

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/login/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(
    relativePath: {eq: "coleta-de-oleo-lubrificante/imagens/fuso.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(
    relativePath: {eq: "coleta-de-oleo-lubrificante/imagens/man-mobile2.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 400, height: 500, layout: CONSTRAINED)
    }
  }
}
`
